import { Agenda, Check, Lock, StartEmpty, StartFill } from "assets/svgs";
import PropTypes from "prop-types";
import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router-dom";

import * as S from "./styles.module.css";

const icon = {
  completed: Check,
  unlocked: Agenda,
  locked: Lock,
};

const colors = {
  completed: "#58CC02",
  unlocked: "#723FB1",
  locked: "#C9C9C9",
};

const text = {
  completed: "Concluída",
  unlocked: "Em progresso",
  locked: "Conclua as unidades anteriores",
};

const LessonItem = ({ state = "completed", progress = 0, index, title, firstCard, stars }) => {
  const history = useHistory();

  const Icon = icon[state];

  const color = colors[state];

  const handleNavigation = () => {
    if (state === "unlocked") {
      history.push("materialCards");
    }

    if (state === "locked") {
      return;
    }

    if (state === "completed") {
      history.push(`materialCards/${firstCard.position}`);
    }
  };

  return (
    <div
      onClick={handleNavigation}
      className={`${state !== "locked" && S.LessonItemHover} ${
        state === "unlocked" && S.LessonItemActive
      } w-full flex flex-1 gap-4 items-center px-6 py-2 rounded-3xl`}>
      <div className="relative w-16 h-16 flex items-center justify-center">
        {state !== "locked" ? (
          <CircularProgressbarWithChildren
            value={progress}
            styles={buildStyles({
              pathColor: colors[state],
              trailColor: "#d6d6d6",
            })}>
            <div
              style={{ backgroundColor: color }}
              className="w-14 h-14 rounded-full flex items-center justify-center border-2 border-white">
              <Icon />
            </div>
          </CircularProgressbarWithChildren>
        ) : (
          <div
            style={{ backgroundColor: color }}
            className="w-14 h-14 rounded-full flex items-center justify-center border-2 border-white">
            <Icon />
          </div>
        )}
      </div>

      <div>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <p cl style={{ color }} className={S.UnityName}>
            Unidade {index + 1}
          </p>

          <div style={{ display: "flex", gap: 3 }}>
            {Array.from({ length: stars }).map((_, index) => (
              <StartFill key={index} active />
            ))}

            {Array.from({ length: 3 - stars }).map((_, index) => (
              <StartEmpty key={index} active={false} />
            ))}
          </div>
        </div>
        <p className={`${S.Title} line-clamp-1`}>{title}</p>
        <p className={S.Description}>{text[state]}</p>
      </div>
    </div>
  );
};

LessonItem.propTypes = {
  state: PropTypes.string,
  title: PropTypes.string,
  progress: PropTypes.number,
  index: PropTypes.number,
  firstCard: PropTypes.object,
  stars: PropTypes.number,
};

export default LessonItem;
