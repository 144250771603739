import Button from "components/MaterialCards/Button/Button";
import ErrorFeedback from "components/MaterialCards/ErrorFeedback/ErrorFeedback";
import { useModalBanner } from "context/ModalBannerContext";
import { UserContext } from "context/UserContext";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

const ButtonFeedback = ({
  showErrorFeedback,
  isCorrect = null,
  isChecking,
  handleSubmit,
  handleNextQuestion,
  showCorrectAnswer,
  showCorrigir = true,
  showFeedbackText = true,
}) => {
  const { setShowBanner } = useModalBanner();
  const { user } = useContext(UserContext);

  const [state, setState] = useState(null);
  const [errorButtonVariant, setErrorButtonVariant] = useState("incorrect");

  const handleCorrectAnswer = () => {
    if (user?.student?.status === "ACTIVE") {
      showCorrectAnswer();
      return;
    }

    setShowBanner(true);
  };

  useEffect(() => {
    const dic = {
      /* estado | condição parar entrar no estado */
      FEEDBACK: !!showErrorFeedback,
      DISABLED: !isChecking && !showErrorFeedback,
      CHECKING: isChecking && !showErrorFeedback,
    };

    // Define o estado que tiver true ou continua undefined
    setState(Object.keys(dic).find((key) => dic[key]));
  }, [showErrorFeedback, isChecking]);

  useEffect(() => {
    setErrorButtonVariant(isCorrect ? "correct" : "incorrect");
  }, [isCorrect]);

  return (
    <div>
      <div className="flex justify-center">
        {state === "DISABLED" && <Button type="callToAction" variant="disabled" text="Validar" disabled={true} />}

        {state === "CHECKING" && (
          <Button onClick={handleSubmit} type="callToAction" variant="checking" text="Validar" />
        )}

        {state === "FEEDBACK" && (
          <div className="flex flex-col items-center justify-end h-[120px] gap-2 w-full">
            {showFeedbackText && <ErrorFeedback isCorrect={isCorrect} />}

            {!isCorrect && showCorrigir && (
              <Button onClick={handleCorrectAnswer} type="callToAction" variant="checking" text="Corrigir" />
            )}

            <Button onClick={handleNextQuestion} type="callToAction" variant={errorButtonVariant} text="Continuar" />
          </div>
        )}
      </div>
    </div>
  );
};

ButtonFeedback.propTypes = {
  showErrorFeedback: PropTypes.bool.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  isChecking: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleNextQuestion: PropTypes.func.isRequired,
  showCorrectAnswer: PropTypes.func,
  showCorrigir: PropTypes.bool,
  showFeedbackText: PropTypes.bool,
};

export default ButtonFeedback;
