import { UserContext } from "context/UserContext";
import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import Carousel from "react-elastic-carousel";
import { useLocation } from "react-router-dom";
import { useClickAway } from "react-use";
import { MATERIALS_CARDS_FEATURE_ENABLED } from "utils/features";

import MaterialCard from "../../../_Shared/MaterialCard/MaterialCard";
import SecondaryCategoryCard from "../../../_Shared/SecondaryCategoryCard/SecondaryCategoryCard";
import TertiaryCategoryCard from "../../../_Shared/TertiaryCategoryCard/TertiaryCategoryCard";
import { Search, SpinnerPurple } from "../../../../assets/svgs";
import { NavbarContext } from "../../../../context/NavbarContext";
import useRequest from "../../../../hooks/useRequest";
import { levelToNumber } from "../../../../utils/level";
import { parseFilterQuery } from "../../../../utils/utils";

import "./Materials.css";

const Materials = () => {
  const { user, showAds } = useContext(UserContext);
  const { setNavigationLinks } = useContext(NavbarContext);

  const { request } = useRequest();
  const { search } = useLocation();
  const refButtonSearch = useRef();
  const parentUuid = new URLSearchParams(search).get("parentUuid");

  const [primary, setPrimary] = useState([]);
  const [secondary, setSecondary] = useState([]);
  const [tertiary, setTertiary] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useState("");

  const [currentFilters] = useState(JSON.parse(sessionStorage.getItem("materialFilters")) ?? []);

  const loadPrimaryCategories = async (primaryCategories) => {
    setPrimary(() =>
      primaryCategories.map((category) => {
        if (MATERIALS_CARDS_FEATURE_ENABLED.includes(category.title)) {
          category.totalMaterials = category.unities;
        }

        return category;
      }),
    );
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 400, itemsToShow: 1 },
    { width: 540, itemsToShow: 2 },
    { width: 700, itemsToShow: 3 },
    { width: 1500, itemsToShow: 4 },
    { width: 1900, itemsToShow: 5 },
  ];

  const currentLevelNumber = useMemo(() => levelToNumber(user?.levelTest), [user?.levelTest]);

  // Adicionar mais variações de cores aqui
  const categoriesColors = ["#733EB1", "#6AAFDD", "#EB5757", "#EC9900", "#2D9974", "#CF8BFB"];
  const secondayColors = ["#6A51B2", "#EC9900", "#2D9974", "#6AAFDD"];
  const tertiaryColors = [
    "rgb(115, 62, 177)",
    "rgb(236, 153, 0)",
    "rgb(45, 153, 116)",
    "rgb(106, 175, 221)",
    "rgb(115, 62, 177)",
    "rgb(236, 153, 0)",
    "rgb(45, 153, 116)",
    "rgb(106, 175, 221)",
    "rgb(115, 62, 177)",
  ];

  useEffect(() => {
    const arrLinks = [
      { title: "Início", path: "/" },
      { title: "Material", path: "/material" },
    ];

    setNavigationLinks(arrLinks);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("materialFilters", JSON.stringify(currentFilters));
  }, [currentFilters]);

  useDebouncedEffect(
    () => {
      setIsLoading(true);

      const filterQuery = parseFilterQuery(currentFilters, query ? "&" : "?");

      let url = `/category/list${query ? "?searchQuery=" + query : ""}${filterQuery ? filterQuery : ""}`;

      if (parentUuid) {
        const separator = url.endsWith("/list") ? "?" : "&";
        url += `${separator}parentUuid=${parentUuid}`;
      }

      request("GET", url).then((response) => {
        if (parentUuid) {
          loadPrimaryCategories(response.primary);
        }

        setSecondary(response.secondary);
        setTertiary(response.tertiary);
        setIsLoading(false);
      });
    },
    [query, currentFilters, parentUuid],
    300,
  );

  useClickAway(refButtonSearch, () => {
    if (query === "") {
      setIsSearching(false);
    }
  });

  let secondaryColorIndex = 0;
  let tertiaryColorIndex = 0;

  return (
    <section className="materials__container">
      {/* banner-/material-1  */}
      {showAds && (
        <div id="eb-ad-slot" style={{ margin: "0 auto" }}>
          <ins
            className="adsbygoogle"
            data-ad-client="ca-pub-9985597315024929"
            data-ad-slot="1025417185"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </div>
      )}
      <div className="flex items-center gap-10 justify-between mb-3">
        {primary.length > 0 && <span className="section__title">Módulos</span>}

        <div className="flex items-center gap-4">
          <button
            type="button"
            className="materials__button-action"
            ref={refButtonSearch}
            onClick={() => setIsSearching(true)}>
            <Search />

            {isSearching && (
              <input
                type="text"
                placeholder="Busque por nome"
                className="input__search"
                value={query}
                onChange={({ target }) => setQuery(target.value)}
              />
            )}
          </button>

          {/* <button
            type="button"
            className="materials__button-action"
            onClick={() => {
              setShowSlide(true);
              setSlideComponent(
                <DrawerMaterialFilters
                  setShowSlide={setShowSlide}
                  setCurrentFilters={setCurrentFilters}
                  currentFilters={currentFilters}
                />,
              );
            }}>
            <Filter />
          </button> */}
        </div>
      </div>

      {(isLoading && <SpinnerPurple className="w-5 m-auto mt-6 mb-8 animate-spin" />) || (
        <>
          {primary.length > 0 && (
            <Carousel
              breakPoints={breakPoints}
              showArrows={false}
              itemPadding={[10]}
              pagination={primary.length > 1}
              itemPosition="START">
              {primary.map((category, index) => {
                const isTeacher = user?.role !== "TEACHER";

                return (
                  <MaterialCard
                    data={category}
                    color={categoriesColors[index]}
                    key={category.id}
                    isDisabled={currentLevelNumber < index && isTeacher && !parentUuid}
                    isCompleted={currentLevelNumber > index && isTeacher}
                  />
                );
              })}
            </Carousel>
          )}
          {secondary.length > 0 && (
            <>
              {showAds && (
                <div id="eb-ad-slot" style={{ margin: "0 auto", marginTop: "22px" }}>
                  {/* banner-/material-2 */}
                  <ins
                    className="adsbygoogle"
                    data-ad-client="ca-pub-9985597315024929"
                    data-ad-slot="7160430752"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                </div>
              )}
              <span className="section__title block mt-10 mb-4">Materiais Complementares</span>

              <Carousel
                breakPoints={breakPoints}
                showArrows={false}
                itemPadding={[10]}
                pagination={secondary.length > 1}
                itemPosition="START">
                {secondary.map((category) => {
                  if (!secondaryColorIndex) {
                    secondaryColorIndex = 0;
                  }

                  return (
                    <SecondaryCategoryCard
                      data={category}
                      color={secondayColors[secondaryColorIndex++]}
                      key={category.id}
                    />
                  );
                })}
              </Carousel>
            </>
          )}
          {tertiary.length > 0 && (
            <>
              {showAds && (
                <div id="eb-ad-slot" style={{ margin: "0 auto", marginTop: "22px" }}>
                  {/* banner-/material-3 */}
                  <ins
                    className="adsbygoogle"
                    data-ad-client="ca-pub-9985597315024929"
                    data-ad-slot="5483498951"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                </div>
              )}
              <span className="section__title block mt-10 mb-4">Cursos</span>

              <Carousel
                breakPoints={breakPoints}
                showArrows={false}
                itemPadding={[10]}
                pagination={tertiary.length > 1}
                itemPosition="START">
                {tertiary.map((category) => {
                  if (!tertiaryColors[tertiaryColorIndex]) {
                    tertiaryColorIndex = 0;
                  }

                  return (
                    <TertiaryCategoryCard
                      data={category}
                      color={tertiaryColors[tertiaryColorIndex++]}
                      key={category.id}
                    />
                  );
                })}
              </Carousel>
            </>
          )}

          {showAds && (
            <div id="eb-ad-slot" style={{ margin: "0 auto", marginTop: "22px" }}>
              {/* banner-/material-4 */}
              <ins
                className="adsbygoogle"
                data-ad-client="ca-pub-9985597315024929"
                data-ad-slot="8544814559"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Materials;
