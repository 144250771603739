import PropTypes from "prop-types";
import React from "react";
import { IoMdClose } from "react-icons/io";

import * as S from "./styles.module.css";

const Tooltip = ({ text, onClick }) => {
  return (
    <div className={S.Wrapper}>
      <button className={S.Close} onClick={onClick}>
        <IoMdClose color="white" />
      </button>

      <div className={S.Message}>
        <p>{text}</p>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tooltip;
