import { Mic } from "assets/svgs";
import axios from "axios";
import AnimatedProgressProvider from "components/_Shared/AnimatedProgressProvider/AnimatedProgressProvider";
import Button from "components/MaterialCards/Button/Button";
import ButtonFeedback from "components/MaterialCards/ButtonFeedback/ButtonFeedback";
import TTSButton from "components/MaterialCards/TTSButton/TTSButton";
import { useChatbot } from "context/ChatbotContext";
import { useMaterialCards } from "context/MaterialCardsContext";
import { useModalBanner } from "context/ModalBannerContext";
import { easeQuadInOut } from "d3-ease";
import { useIsStudent } from "hooks/useIsStudent";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { buildStyles } from "react-circular-progressbar";
import { TooltipDic } from "utils/utils";

import RecorderService from "./Recorder";
import * as S from "./styles.module.css";

var toWav = require("audiobuffer-to-wav");

const VoiceBay = ({ nextCard }) => {
  const { setShowTutor, setShowChat } = useChatbot();
  const { isActiveStudent } = useIsStudent();
  const { setShowBanner } = useModalBanner();
  const { question } = useMaterialCards();

  const [isRecoding, setIsRecording] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recorderSrvc, setRecorderSrvc] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const [mood, setMood] = useState("neutral");

  const [feedback, setFeedback] = useState();

  const [response, setResponse] = useState();

  const onNewRecording = async (evt) => {
    setIsLoading(true);

    const url = evt.detail.recording.blobUrl;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Response not OK (${response.status})`);
      }

      const arrayBuffer = await response.arrayBuffer();

      if (arrayBuffer.byteLength === 0) {
        return;
      }

      new AudioContext().decodeAudioData(arrayBuffer, async (buffer) => {
        var wav = toWav(buffer);

        const formData = new FormData();
        formData.append("file", new Blob([wav]), "voice.wav");
        formData.append("referenceText", question.text);

        const response = await axios.post(process.env.REACT_APP_API_URL + "/card/checkPronunciation", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setIsLoading(false);

        setResponse(response.data);

        if (response) {
          setIsChecking(true);
          return;
        }
      });
    } catch (ex) {
      console.log(ex instanceof Error ? ex : new Error(String(ex)));
    }
  };

  const startRecording = async (event) => {
    // Previne que o usuário grave outra resposta enquanto a resposta está corrigida
    if (showErrorFeedback) {
      return;
    }

    event.preventDefault();

    await recorderSrvc.startRecording();
    setIsRecording(true);
  };

  const handleStopRecording = async (event) => {
    event.preventDefault();
    recorderSrvc.stopRecording();
    setIsRecording(false);
  };

  const validateAnswer = () => {
    if (normalizeText(response.Display) !== normalizeText(question.text)) {
      setIsCorrect(false);
      setShowErrorFeedback(true);
      setFeedback("A resposta não corresponde ao texto de referência.");

      setMood("sad");
      return;
    }

    if (response.PronunciationAssessment.AccuracyScore > 85) {
      setIsCorrect(true);
      setFeedback("A pronúncia está correta.");
      setMood("happy");
    } else {
      setMood("sad");
      setFeedback("A pronúncia não está correta.");
      setIsCorrect(false);
    }

    setShowErrorFeedback(true);
  };

  const handleNextQuestion = async () => {
    await nextCard({ answer: question.title, isCorrect });
    localStorage.setItem("userAlreadyUsedVoice", "true");

    // reseta os estados.
    setShowErrorFeedback(false);
    setIsRecording(false);
    setIsChecking(false);
    setIsCorrect(false);
  };

  const handleCantTalk = () => {
    setShowErrorFeedback(false);
    nextCard({ answer: "_voice_skip", isCorrect: true });
  };

  const checkIfCorrectAndGetColor = (percent) => (percent > 85 ? "#58cc02" : "#ea2b2b");

  const handleUnluckBay = () => {
    setShowBanner("Premium");
  };

  const normalizeText = (text) => {
    if (!text) {
      return "";
    }

    return text
      .trim()
      .toLowerCase()
      .replace(/[.,?!…]/g, "");
  };

  const buttonPulse = isRecoding ? S.pulse : "";

  const buttonStyle = `${S.button} ${buttonPulse} pointer-events-none	`;

  useEffect(() => {
    const service = new RecorderService();
    service.em.addEventListener("recording", (evt) => onNewRecording(evt));

    setRecorderSrvc(service);
    setMood("neutral");

    setShowTutor(false);
    setShowChat(false);

    const userAlreadyUsedVoice = localStorage.getItem("userAlreadyUsedVoice") === "true";

    if (isActiveStudent) {
      setDisabled(isActiveStudent);

      return;
    }

    if (userAlreadyUsedVoice) {
      setDisabled(false);
      return;
    }
  }, [question, isActiveStudent]);

  return (
    <div className={S.wrapper}>
      {!showErrorFeedback && <div className={S.title}>{question?.title}</div>}

      <div className={S.speaking}>
        <img alt="gif" src={TooltipDic[mood].url} className={S.Iframe} />

        {showErrorFeedback ? (
          <div className={S.Feedback}>
            <div className="flex flex-col md:flex-row gap-5 items-center md:gap-20">
              <div className="w-full text-center">
                <TTSButton phrase={question?.text} />
              </div>

              <div className="w-full text-center">
                <h1>Suas resposta:</h1>

                <div className="text-md font-bold">
                  {response.Words.map((word) => (
                    <span
                      key={word.Word}
                      style={{
                        color: checkIfCorrectAndGetColor(word.PronunciationAssessment.AccuracyScore),
                      }}>
                      {word.Word}{" "}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <h1>Pontuação de pronúncia:</h1>

            <div
              style={{
                width: "100px",
                height: "100px",
              }}
              className="flex items-center justify-center my-8">
              <AnimatedProgressProvider
                valueStart={0}
                valueEnd={response.PronunciationAssessment.AccuracyScore}
                duration={2}
                easingFunction={easeQuadInOut}>
                {(value) => {
                  const roundedValue = Math.round(value);
                  return (
                    <CircularProgressbar
                      strokeWidth={10}
                      value={value}
                      text={`${roundedValue}%`}
                      styles={buildStyles({
                        pathColor: checkIfCorrectAndGetColor(value),
                        textColor: checkIfCorrectAndGetColor(value),
                        trailColor: "#d6d6d6",
                        pathTransition: "none",
                        textStyle: { fontWeight: "bold" },
                      })}
                    />
                  );
                }}
              </AnimatedProgressProvider>
            </div>

            <p className="text-md font-bold text-center" style={{ color: isCorrect ? "#58cc02" : "#ea2b2b" }}>
              {feedback}
            </p>
          </div>
        ) : (
          <>
            <TTSButton phrase={question?.text} />

            <div className={S.wrapperButton}>
              {disabled ? (
                <button
                  onMouseUp={(event) => handleStopRecording(event)}
                  onMouseDown={(event) => startRecording(event)}
                  onTouchStart={(event) => startRecording(event)}
                  onTouchEnd={(event) => handleStopRecording(event)}
                  onMouseLeave={(event) => handleStopRecording(event)}
                  className={buttonStyle}
                  style={{
                    backgroundColor: showErrorFeedback ? "#BAE7FC" : "#1cb0f6",
                    cursor: showErrorFeedback ? "not-allowed" : "pointer",
                  }}
                  type="button">
                  <Mic />
                </button>
              ) : (
                <button
                  className={buttonStyle}
                  style={{
                    backgroundColor: "gray",
                    cursor: "not-allowed",
                  }}
                  type="button">
                  <Mic />
                </button>
              )}

              <div
                className={S.UserSelectNone}
                style={{
                  userSelect: "none",
                }}>
                {disabled ? (
                  <>
                    {isRecoding && <p>Gravando...</p>}
                    {!isRecoding && <p>Segure para gravar</p>}
                  </>
                ) : (
                  <p>Assine para praticar sua pronúncia.</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className={S.wrapperButton}>
        {!disabled ? (
          <Button onClick={handleUnluckBay} type="callToAction" variant="checking" text="Desbloquear a BAY" />
        ) : (
          <>
            <div className={S.buttonSubmit}>
              {isLoading ? (
                <div className="flex justify-center">
                  <Button type="callToAction" variant="disabled" text="Carregando...." />
                </div>
              ) : (
                <ButtonFeedback
                  showErrorFeedback={showErrorFeedback}
                  isCorrect={isCorrect}
                  isChecking={isChecking}
                  handleNextQuestion={handleNextQuestion}
                  handleSubmit={validateAnswer}
                  showCorrigir={false}
                  showFeedbackText={false}
                />
              )}
            </div>
          </>
        )}

        {disabled ? (
          <>
            {!showErrorFeedback && (
              <button onClick={handleCantTalk} className={S.text}>
                Não consigo falar agora
              </button>
            )}
          </>
        ) : (
          <button onClick={handleCantTalk} className={S.text}>
            Continuar
          </button>
        )}
      </div>
    </div>
  );
};

VoiceBay.propTypes = {
  nextCard: PropTypes.func.isRequired,
};

export default VoiceBay;
