import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { FaCrown } from "react-icons/fa";

import * as S from "./styles.module.css";

const BannerPraticeConversationHome = () => {
  return (
    <div className={S.Wrapper}>
      <div className="flex items-center gap-8">
        <FaCrown color="white" size="2.5rem" />

        <h2 className={S.Title}>
          Grupos de conversação <span>ILIMITADO</span> assine o premium
        </h2>
      </div>

      <button>
        <BiChevronRight color="white" size={42} />
      </button>
    </div>
  );
};

export default BannerPraticeConversationHome;
