import { getAnalytics, setUserId } from "firebase/analytics";
import PropTypes from "prop-types";
import React, { createContext, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { loadFirebase } from "utils/firebase-analytics";

import useRequest from "../hooks/useRequest";
import { getCurrentToken, saveUser, getUser, clearStorage } from "../utils/localStorage";

export const UserContext = createContext();

export const UserStorage = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showAds, setShowAds] = useState(false);
  const [credits, setCredits] = useState(null);

  const { request } = useRequest();
  const history = useHistory();

  useEffect(async () => {
    if (getCurrentToken()) {
      const user = getUser();

      if (user) {
        if (!user.profilePictureUrl) {
          user.profilePictureUrl = process.env.REACT_APP_PROFILE_AVATAR_URL;
        }
        if (!user.bannerUrl) {
          user.bannerUrl = process.env.REACT_APP_PROFILE_BANNER_URL;
        }
      }

      setUserContext(user);

      const userReq = await request("GET", "/user/read");

      if (userReq) {
        if (!userReq.profilePictureUrl) {
          userReq.profilePictureUrl = process.env.REACT_APP_PROFILE_AVATAR_URL;
        }

        if (!userReq.bannerUrl) {
          userReq.bannerUrl = process.env.REACT_APP_PROFILE_BANNER_URL;
        }

        if (userReq.onBoarding) {
          localStorage.setItem("userData", JSON.stringify(userReq.onBoarding));
        }

        setUser(userReq);
        saveUser(userReq);
        request("POST", "/user/heartbeat");
      }
    }
  }, []);

  useEffect(() => {
    const isActiveStudent = user?.role === "STUDENT" && user?.student.status === "ACTIVE";

    const isTeacher = user?.role === "TEACHER";

    setShowAds(!isActiveStudent && !isTeacher); // se não for aluno ativo ou professor mostra os ads;

    if (user?.role && user.role === "STUDENT") {
      getCredits();
    }
  }, [user?.role]);

  useEffect(async () => {
    if (user) {
      saveUser(user);
    }
  }, [user]);

  const logout = async () => {
    await request("POST", "/auth/logout");

    clearStorage();
    setUser(undefined);
    setCredits({});

    return history.push("/");
  };

  const updateUserContext = async () => {
    const user = await request("GET", "/user/read");
    setUserContext(user);
    saveUser(user);

    return user;
  };

  const setCaptchaToken = (token) => localStorage.setItem("x-captcha-token", token);

  const getCredits = async () => {
    const result = await request("GET", "/lesson/getLessonCreditsCount");
    setCredits(result);
  };

  const setPlaceHolders = (user) => {
    if (!user?.profilePictureUrl) {
      user.profilePictureUrl = process.env.REACT_APP_PROFILE_AVATAR_URL;
    }
    if (!user?.bannerUrl) {
      user.bannerUrl = process.env.REACT_APP_PROFILE_BANNER_URL;
    }

    return user;
  };

  const getOnBoardingPayload = () => {
    const onboardingStorage = localStorage.getItem("userData");

    if (!onboardingStorage || onboardingStorage == "undefined") {
      return;
    }

    const {
      age,
      day,
      interest,
      reason,
      schedule,
      wantTestClass,
      levelTest,
      preference,
      origin,
      wantConversation,
      userPhoneNumber,
      level,
    } = JSON.parse(onboardingStorage);

    return {
      age,
      reason,
      interest,
      wantTestClass: wantTestClass ?? "Não",
      testClassDay: day,
      testClassTime: schedule,
      levelTest,
      preference,
      wantConversation,
      userPhoneNumber,
      origin,
      level,
    };
  };

  const setUserContext = async (user) => {
    const userPlaceholders = setPlaceHolders(user);

    if (user?.uuid) {
      const app = loadFirebase();
      setUserId(getAnalytics(app), user.uuid);
    }

    setUser(userPlaceholders);

    if (user?.onBoarding) {
      localStorage.setItem("userData", JSON.stringify(user.onBoarding));
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        showAds,
        credits,
        setUserContext,
        getCredits,
        logout,
        updateUserContext,
        setUser,
        setCaptchaToken,
        getOnBoardingPayload,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

UserStorage.propTypes = {
  children: PropTypes.node.isRequired,
};
